import './App.css';
import React, { useState, useEffect } from 'react'
import MetaTags from 'react-meta-tags';
import Logo from './images/logo.png'

import 'bootstrap/dist/css/bootstrap.min.css';

import axios from 'axios';
import { useInterval } from 'usehooks-ts'

import Web3Modal from "web3modal";
import web3 from "web3";
import { ethers, providers } from "ethers";

import CONTRACT from "./contract.js";
import { providerOptions } from './connectors'
import { ButtonToolbar } from 'react-bootstrap';

const web3Modal = new Web3Modal({
  //network: 'rinkeby', // if null == mainnet.
  cacheProvider: false, // optional
  providerOptions // required
});


var contract = null;
let provider;

function App() {

  const [walletConnected, setWalletConnected] = useState(false)
  const [address, setAddress] = useState(null)
  //const [hash, setHash] = useState(null)
  const [myWeb3Provider, setMyWeb3Provider] = useState(null)
  const [myWeb3Signer, setMyWeb3Signer] = useState(null)

  const [nonce, setNonce] = useState(null)

  // useEffect(()=>{
  //   connectWeb3();
  // }, [])

  const nonceUrl = 'https://verify.yuckpass.com/account/nonce?address=';
  const verifyUrl = 'https://verify.yuckpass.com/account/verify';

  function initProvider() {
    function disconnectAll() {
      setWalletConnected(false)
      setAddress(null)
    }
    // Subscribe to accounts change
    provider.on("accountsChanged", (accounts) => {
      console.log('accountsChanged');
      console.log(accounts);
      if (accounts.length === 0) {
        disconnectAll()
      } else {
        setAddress(provider.selectedAddress)
      }
    });

    // Subscribe to chainId change
    provider.on("chainChanged", (chainId) => {
      console.log('chainChanged');
      console.log(chainId);
    });

    // Subscribe to provider connection
    provider.on("connect", (info) => {
      console.log('onConnect');
      console.log(info);
      setAddress(provider.selectedAddress)
      setWalletConnected(true)
    });

    // Subscribe to provider disconnection
    provider.on("disconnect", (error) => {
      console.log('disconnect');
      console.log(error);
      disconnectAll()
    });
  }

  async function connectWeb3() {
    console.log('Connect Web3');
    provider = await web3Modal.connect()
    console.log(provider);
    const web3Provider = new providers.Web3Provider(provider)
    await web3Provider.provider.enable()
    let mySigner = web3Provider.getSigner()
    setMyWeb3Signer(mySigner);
    setMyWeb3Provider(web3Provider)
    let myAddress = await mySigner.getAddress();
    initProvider()
    setAddress(myAddress)
    setWalletConnected(true);

    if(myAddress) {
      try {
        const response = await axios.get(nonceUrl + myAddress);
        if(response.data.success) {
          setNonce(response.data.nonce);
        }
      } catch (error) {
        console.log(error)
      }
    }
  }

  function connectButton() {
    console.log(walletConnected);
    console.log(address);

    const windowUrl = window.location.search;

    const params = new URLSearchParams(windowUrl);
    let hash = params.get('hash');
    //setHash(hash);

    if(!hash) {
      return <a href="#" class="btn btn-danger" disabled>Error, please try again from Discord.</a>;
    }

    let btn = <a href="#" class="btn btn-primary" onClick={() => {connectWeb3()}}>Connect Wallet</a>;
    if(walletConnected) {
      if(!nonce) {
        return <a href="#" class="btn btn-warning" disabled>Loading random verify pin..</a>;
      }
      btn = <a href="#" class="btn btn-success" onClick={() => {signMessage(nonce)}}>Click here to verify wallet</a>;
    }
    return btn;
  }

  async function signMessage(nonce){
   //const web3 = new window.Web3(window.ethereum);
   const windowUrl = window.location.search;
   const params = new URLSearchParams(windowUrl);
   let hash = params.get('hash');

    let rawMessage = 'Im signing a request to verify, pin: ' + nonce
    let signedMessage;
    if (web3.wc) {
      signedMessage = await myWeb3Provider.send(
        'personal_sign',
        [ ethers.utils.hexlify(ethers.utils.toUtf8Bytes(rawMessage)), address.toLowerCase() ]
      );
    } else { 
      signedMessage = await myWeb3Signer.signMessage(rawMessage)
    }
    let verified = ethers.utils.verifyMessage(rawMessage, signedMessage);
    console.log(signedMessage);
    try {
      let urlString = `?address=${address}&hash=${hash}&sign=${signedMessage}`
      const response = await axios.get(verifyUrl + urlString);
      /*if(!response.data.success) {
        alert(response.data.message);
      } else {
        alert('Done')
      }*/
      alert(response.data.message);
    } catch (error) {
      console.log(error)
    }
  }

 
  return (
    <div className="App"> 
          <MetaTags>
            <title>YuckPass: Verify your wallet</title>
            <meta name="description" content="Verify your wallet" />
          </MetaTags>
      
      
        <div class="section verify-header">
          <div class="container">
            <img src={Logo} alt="Yuck Pass Logo" class="logo"/>
          </div>
        </div>

        <div class="section login-box">
          <div class="container">
            <div class="row justify-content-md-center">
              <div class="col-md-8">
                <div class="box">
                  <h3>Connect your wallet</h3>
                  <div class="intro">Connect your wallet to gain access to your NFT role in Discord.</div>

                  <div class="note alert alert-secondary"><strong>Note:</strong> You must first connect your wallet and then click the "Click here to verify wallet" button.</div>

                  <div class="get-started">
                    {connectButton()}
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>

        <div class="section warn">
          <div class="container">
            Whenever you are connecting your wallet make sure you are on YUCKPASS.COM <br></br>
            <strong>You can disconnect your wallet from this website after you are finished connecting.</strong>
          </div>
        </div>

    </div>
  );
}

export default App;
